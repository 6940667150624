import React from 'react';
import { Shield, AlertTriangle, Wrench, Check, Info, HelpCircle, Users, FileWarning, Brain, CheckCircle } from 'lucide-react';

export default function SafetyTips() {
  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Safety Tips for Using Raised Toilet Seats
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            A raised toilet seat can be a vital tool for individuals with mobility challenges, providing comfort, accessibility, and independence in the bathroom. Follow these essential safety tips to ensure a secure and confident experience.
          </p>
        </div>

        {/* Main Content */}
        <div className="space-y-12">
          {/* Section 1: Choose the Right Type */}
          <section>
            <div className="flex items-center mb-6">
              <Shield className="h-8 w-8 text-blue-600 mr-3" />
              <h2 className="text-2xl font-bold text-gray-900">
                1. Choose the Right Type of Raised Toilet Seat
              </h2>
            </div>
            <p className="text-lg text-gray-600 mb-6">
              Selecting a seat that suits the user's needs and the toilet's design is the first step toward safe usage.
            </p>
            <div className="bg-blue-50 rounded-lg p-6">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <CheckCircle className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <div>
                    <span className="font-semibold">Clamp-On Models:</span>
                    <span className="text-gray-600"> Ideal for users needing a quick and easy solution, but they must be secured tightly to prevent shifting.</span>
                  </div>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <div>
                    <span className="font-semibold">Bolt-On Models:</span>
                    <span className="text-gray-600"> Provide added stability, especially for long-term use or for users who require extra support.</span>
                  </div>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <div>
                    <span className="font-semibold">Freestanding Models:</span>
                    <span className="text-gray-600"> Include handles and legs for enhanced stability, making them suitable for individuals with significant mobility challenges.</span>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          {/* Section 2: Proper Installation */}
          <section>
            <div className="flex items-center mb-6">
              <Wrench className="h-8 w-8 text-blue-600 mr-3" />
              <h2 className="text-2xl font-bold text-gray-900">
                2. Ensure Proper Installation
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-gray-50 rounded-lg p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">Installation Steps</h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <Check className="h-6 w-6 text-green-600 mt-1 mr-3" />
                    <span className="text-gray-600">Follow manufacturer instructions carefully</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-6 w-6 text-green-600 mt-1 mr-3" />
                    <span className="text-gray-600">Test stability after installation</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-6 w-6 text-green-600 mt-1 mr-3" />
                    <span className="text-gray-600">Secure all fastenings properly</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-6 w-6 text-green-600 mt-1 mr-3" />
                    <span className="text-gray-600">Use appropriate tools for installation</span>
                  </li>
                </ul>
              </div>
              <div className="bg-yellow-50 rounded-lg p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">Important Checks</h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <AlertTriangle className="h-6 w-6 text-yellow-600 mt-1 mr-3" />
                    <span className="text-gray-600">Check for wobbling or movement</span>
                  </li>
                  <li className="flex items-start">
                    <AlertTriangle className="h-6 w-6 text-yellow-600 mt-1 mr-3" />
                    <span className="text-gray-600">Verify all bolts are tight but not overtightened</span>
                  </li>
                  <li className="flex items-start">
                    <AlertTriangle className="h-6 w-6 text-yellow-600 mt-1 mr-3" />
                    <span className="text-gray-600">Ensure proper alignment with toilet bowl</span>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* Section 3: User Needs */}
          <section>
            <div className="flex items-center mb-6">
              <Users className="h-8 w-8 text-blue-600 mr-3" />
              <h2 className="text-2xl font-bold text-gray-900">
                3. Match the Seat to the User's Needs
              </h2>
            </div>
            <div className="bg-blue-50 rounded-lg p-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">Height Adjustment</h3>
                  <p className="text-gray-600">Choose seats with adjustable height settings for proper body alignment</p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">Weight Capacity</h3>
                  <p className="text-gray-600">Verify weight limits, especially for bariatric users</p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">Support Features</h3>
                  <p className="text-gray-600">Consider models with built-in armrests for added stability</p>
                </div>
              </div>
            </div>
          </section>

          {/* Section 4: Additional Safety Features */}
          <section>
            <div className="flex items-center mb-6">
              <Shield className="h-8 w-8 text-blue-600 mr-3" />
              <h2 className="text-2xl font-bold text-gray-900">
                4. Use Additional Safety Accessories
              </h2>
            </div>
            <div className="bg-gray-50 rounded-lg p-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="text-center">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">Grab Bars</h3>
                  <p className="text-gray-600">Install securely mounted grab bars for balance support</p>
                </div>
                <div className="text-center">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">Non-Slip Mats</h3>
                  <p className="text-gray-600">Use around toilet area to prevent slipping</p>
                </div>
                <div className="text-center">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">Safety Frames</h3>
                  <p className="text-gray-600">Consider additional support frames with handles</p>
                </div>
              </div>
            </div>
          </section>

          {/* Section 5: Maintenance */}
          <section>
            <div className="flex items-center mb-6">
              <Wrench className="h-8 w-8 text-blue-600 mr-3" />
              <h2 className="text-2xl font-bold text-gray-900">
                5. Perform Regular Maintenance Checks
              </h2>
            </div>
            <div className="bg-blue-50 rounded-lg p-6">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <Check className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <div>
                    <span className="font-semibold">Regular Inspections:</span>
                    <span className="text-gray-600"> Check for damage, loose parts, and wear regularly</span>
                  </div>
                </li>
                <li className="flex items-start">
                  <Check className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <div>
                    <span className="font-semibold">Cleaning:</span>
                    <span className="text-gray-600"> Sanitize regularly with appropriate cleaning products</span>
                  </div>
                </li>
                <li className="flex items-start">
                  <Check className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <div>
                    <span className="font-semibold">Part Replacement:</span>
                    <span className="text-gray-600"> Replace worn components promptly</span>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          {/* Common Mistakes to Avoid */}
          <section>
            <div className="flex items-center mb-6">
              <FileWarning className="h-8 w-8 text-red-600 mr-3" />
              <h2 className="text-2xl font-bold text-gray-900">
                Common Mistakes to Avoid
              </h2>
            </div>
            <div className="bg-red-50 rounded-lg p-6">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <AlertTriangle className="h-6 w-6 text-red-600 mt-1 mr-3" />
                  <span className="text-gray-800">Skipping regular stability checks</span>
                </li>
                <li className="flex items-start">
                  <AlertTriangle className="h-6 w-6 text-red-600 mt-1 mr-3" />
                  <span className="text-gray-800">Ignoring weight capacity limits</span>
                </li>
                <li className="flex items-start">
                  <AlertTriangle className="h-6 w-6 text-red-600 mt-1 mr-3" />
                  <span className="text-gray-800">Neglecting surrounding hazards</span>
                </li>
                <li className="flex items-start">
                  <AlertTriangle className="h-6 w-6 text-red-600 mt-1 mr-3" />
                  <span className="text-gray-800">Using incompatible seat models</span>
                </li>
              </ul>
            </div>
          </section>

          {/* Professional Help */}
          <section>
            <div className="flex items-center mb-6">
              <HelpCircle className="h-8 w-8 text-blue-600 mr-3" />
              <h2 className="text-2xl font-bold text-gray-900">
                When to Seek Professional Help
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-gray-50 rounded-lg p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">Installation Assistance</h3>
                <p className="text-gray-600">Consider professional installation for complex setups or when unsure about proper mounting</p>
              </div>
              <div className="bg-gray-50 rounded-lg p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">Medical Consultation</h3>
                <p className="text-gray-600">Consult healthcare professionals for specific recommendations based on individual needs</p>
              </div>
            </div>
          </section>

          {/* Conclusion */}
          <section className="bg-blue-50 rounded-lg p-6">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Conclusion</h2>
            <p className="text-lg text-gray-600 mb-6">
              Safety is paramount when using a raised toilet seat. By following these guidelines and maintaining regular safety checks, you can ensure a secure and comfortable experience for users.
            </p>
            <div className="flex items-start">
              <Info className="h-6 w-6 text-blue-600 mt-1 mr-3 flex-shrink-0" />
              <p className="text-gray-600">
                <strong>💡 Remember:</strong> Prevention is better than cure—always prioritize safety checks and address potential issues promptly to maintain a reliable and accessible setup.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
