import React from 'react';
import { Info, CheckCircle, AlertTriangle, HelpCircle, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export function MainContent() {
  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Introduction Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Understanding Raised Toilet Seats</h2>
          <p className="text-lg text-gray-600 mb-4">
            Raised toilet seats are essential bathroom accessories designed to increase the height of standard toilets, making them more accessible and comfortable to use. A standard toilet height of 14 inches, while suitable for people of smaller stature, can present challenges for many adults, particularly those with mobility issues or recovering from surgery.
          </p>
          <p className="text-lg text-gray-600">
            By elevating the seat height to at least 18 inches, raised toilet seats significantly reduce the strain on knees and joints during sitting and standing motions, promoting independence and safety in the bathroom. For comprehensive bathroom safety, consider pairing your raised toilet seat with a <a href="https://showerchairhub.com" className="text-blue-600 hover:underline" target="_blank" rel="noopener">quality shower chair</a> to create a fully accessible bathroom environment.
          </p>
        </div>

        {/* Key Benefits Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          <div className="bg-blue-50 p-6 rounded-lg">
            <div className="flex items-start">
              <CheckCircle className="h-6 w-6 text-blue-600 mt-1 mr-3" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Improved Accessibility</h3>
                <p className="text-gray-600">
                  The additional 4-6 inches of height makes a significant difference in ease of use, particularly for those with limited mobility, arthritis, or recent hip or knee surgery. For enhanced mobility throughout your home, consider using a <a href="https://walkersupport.com" className="text-blue-600 hover:underline" target="_blank" rel="noopener">walker</a> in conjunction with bathroom safety equipment.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-blue-50 p-6 rounded-lg">
            <div className="flex items-start">
              <Info className="h-6 w-6 text-blue-600 mt-1 mr-3" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Reduced Joint Stress</h3>
                <p className="text-gray-600">
                  The elevated height minimizes the distance needed to lower and raise yourself, significantly reducing stress on your knees, hips, and back.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Important Considerations */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Important Considerations</h2>
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-6">
            <div className="flex">
              <AlertTriangle className="h-6 w-6 text-yellow-600 mt-1 mr-3" />
              <div>
                <h3 className="text-xl font-semibold text-yellow-800 mb-2">Safety First</h3>
                <p className="text-yellow-700">
                  When choosing a raised toilet seat, consider factors such as:
                </p>
                <ul className="list-disc list-inside mt-2 text-yellow-700">
                  <li>Weight capacity</li>
                  <li>Secure attachment mechanism</li>
                  <li>Non-slip surface</li>
                  <li>Proper fit for your toilet model</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Safety Tips Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Essential Safety Tips</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Installation Safety */}
            <div className="bg-blue-50 p-6 rounded-lg">
              <div className="flex items-start">
                <AlertTriangle className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">Installation Safety</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li>• Always follow manufacturer's installation instructions carefully</li>
                    <li>• Verify the weight capacity matches your needs</li>
                    <li>• Check that all mounting brackets and clamps are properly secured</li>
                    <li>• Test the seat stability before full weight application</li>
                  </ul>
                  <div className="mt-4">
                    <a 
                      href="/downloads/maintenance-checklist.html" 
                      className="inline-flex items-center text-blue-600 hover:text-blue-800"
                      target="_blank"
                      rel="noopener"
                    >
                      Download our comprehensive maintenance checklist
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Usage Guidelines */}
            <div className="bg-blue-50 p-6 rounded-lg">
              <div className="flex items-start">
                <CheckCircle className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">Usage Guidelines</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li>• Center your weight when sitting down and standing up</li>
                    <li>• Use grab bars for additional support when available</li>
                    <li>• Inspect for wear and tear before each use</li>
                    <li>• Clean regularly with non-abrasive cleaners</li>
                    <Link to="/blog/safety-tips-raised-toilet-seats" className="text-blue-600 hover:underline block mt-2">
                      View complete usage guide →
                    </Link>
                  </ul>
                </div>
              </div>
            </div>

            {/* Environmental Safety */}
            <div className="bg-blue-50 p-6 rounded-lg">
              <div className="flex items-start">
                <HelpCircle className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">Bathroom Environment</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li>• Ensure adequate lighting in the bathroom area</li>
                    <li>• Use non-slip mats around the toilet</li>
                    <li>• Keep the path to the toilet clear of obstacles</li>
                    <li>• Consider installing motion-sensor lights for nighttime use</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Special Considerations */}
            <div className="bg-blue-50 p-6 rounded-lg">
              <div className="flex items-start">
                <Info className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">Special Considerations</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li>• Follow post-surgery recovery guidelines from your healthcare provider</li>
                    <li>• Coordinate with mobility aids like walkers when necessary</li>
                    <li>• Consider caregiver assistance during initial usage</li>
                    <li>• Keep emergency contact numbers easily accessible</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Maintenance Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Maintenance Guide</h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Cleaning and Disinfection */}
            <div className="bg-green-50 p-6 rounded-lg">
              <div className="flex items-start">
                <CheckCircle className="h-6 w-6 text-green-600 mt-1 mr-3" />
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">Cleaning & Disinfection</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li>• Use mild soap and warm water for daily cleaning</li>
                    <li>• Avoid abrasive cleaners that can damage the surface</li>
                    <li>• Recommended disinfectants:
                      <ul className="ml-4 mt-2 space-y-1">
                        <li>- Hospital-grade quaternary disinfectants</li>
                        <li>- Diluted bleach solution (1:10 ratio)</li>
                        <li>- Alcohol-based sanitizers (70%)</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Anti-Slip Measures */}
            <div className="bg-green-50 p-6 rounded-lg">
              <div className="flex items-start">
                <AlertTriangle className="h-6 w-6 text-green-600 mt-1 mr-3" />
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">Preventing Slippage</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li>• Check anti-slip pads regularly</li>
                    <li>• Replace worn pads immediately</li>
                    <li>• Consider textured surfaces for added grip</li>
                    <li>• Keep surfaces clean and dry</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Wear and Tear Inspection */}
            <div className="bg-green-50 p-6 rounded-lg">
              <div className="flex items-start">
                <HelpCircle className="h-6 w-6 text-green-600 mt-1 mr-3" />
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">Wear & Tear Signs</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li>• Inspect mounting brackets weekly</li>
                    <li>• Check for cracks or stress marks</li>
                    <li>• Monitor stability and wobbling</li>
                    <li>• Verify all fasteners are tight</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Maintenance Checklist */}
          <div className="mt-8 bg-green-50 p-6 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <Info className="h-6 w-6 text-green-600 mr-2" />
              Long-Term Maintenance Checklist
            </h3>
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h4 className="font-medium text-gray-900 mb-2">Daily Tasks:</h4>
                <ul className="space-y-2 text-gray-600">
                  <li>✓ Wipe down seat surface</li>
                  <li>✓ Check for loose components</li>
                  <li>✓ Ensure proper alignment</li>
                </ul>
              </div>
              <div>
                <h4 className="font-medium text-gray-900 mb-2">Weekly Tasks:</h4>
                <ul className="space-y-2 text-gray-600">
                  <li>✓ Deep clean all surfaces</li>
                  <li>✓ Inspect mounting hardware</li>
                  <li>✓ Check stability and movement</li>
                </ul>
              </div>
              <div>
                <h4 className="font-medium text-gray-900 mb-2">Monthly Tasks:</h4>
                <ul className="space-y-2 text-gray-600">
                  <li>✓ Replace anti-slip pads if needed</li>
                  <li>✓ Tighten all fasteners</li>
                  <li>✓ Full stability assessment</li>
                </ul>
              </div>
              <div>
                <h4 className="font-medium text-gray-900 mb-2">Quarterly Tasks:</h4>
                <ul className="space-y-2 text-gray-600">
                  <li>✓ Complete hardware inspection</li>
                  <li>✓ Check weight capacity compliance</li>
                  <li>✓ Evaluate for replacement needs</li>
                </ul>
              </div>
            </div>
            <a href="/downloads/maintenance-checklist.html" className="text-green-600 hover:underline block mt-4">
              Download printable maintenance checklist →
            </a>
          </div>
        </div>

        {/* Complete Bathroom Safety Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Complete Bathroom Safety Solutions</h2>
          <p className="text-lg text-gray-600 mb-4">
            Creating a safe bathroom environment often requires multiple safety solutions working together. While a raised toilet seat provides essential support, consider complementing it with other safety equipment such as a <a href="https://showerchairhub.com" className="text-blue-600 hover:underline" target="_blank" rel="noopener">shower chair</a> for bathing safety. For those who need assistance moving around their home, a properly fitted <a href="https://walkersupport.com" className="text-blue-600 hover:underline" target="_blank" rel="noopener">walker</a> can provide the necessary stability and confidence.
          </p>
        </div>

        {/* Featured Products Section */}
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Discover Our Featured Products
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            We've carefully selected the best raised toilet seats to help you make an informed decision.
            Each product is chosen for its quality, comfort, and safety features.
          </p>
          <div className="mt-8">
            <Link
              to="/featured-products"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              Browse Featured Products
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>

        <div className="mt-16">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl text-center">
            Why Choose Our Recommendations?
          </h2>
          <div className="mt-12 grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Expert Reviews</h3>
              <p className="text-gray-600">
                Each product is thoroughly evaluated for quality, durability, and user satisfaction.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Safety First</h3>
              <p className="text-gray-600">
                We prioritize products with excellent safety features and stability.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Trusted Brands</h3>
              <p className="text-gray-600">
                We only recommend products from reputable manufacturers with proven track records.
              </p>
            </div>
          </div>
        </div>

        {/* Help Section */}
        <div className="text-center">
          <div className="inline-flex items-center mb-4">
            <HelpCircle className="h-8 w-8 text-blue-600 mr-2" />
            <h2 className="text-3xl font-bold text-gray-900">Ready to Find Your Perfect Fit?</h2>
          </div>
          <p className="text-lg text-gray-600 mb-6">
            Browse our carefully selected collection of high-quality raised toilet seats.
          </p>
          <a
            href="https://shareasale.com/r.cfm?b=2278&u=1520322&m=942&urllink=www%2Ethewrightstuff%2Ecom%2Fraised%2Dtoilet%2Dseats%2Ehtml%3Fviewall%3D1&afftrack="
            className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
            target="_blank"
            rel="nofollow noopener"
          >
            Shop Now
            <ArrowRight className="ml-2 h-5 w-5" />
          </a>
        </div>
      </div>
    </div>
  );
}