import React from 'react';
import { Wrench, CheckSquare, AlertTriangle, Info } from 'lucide-react';

export default function InstallationTips() {
  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Installation Tips for Raised Toilet Seats
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Installing a raised toilet seat can significantly improve accessibility and comfort for individuals with mobility challenges. This guide provides practical tips for installing raised toilet seats securely and effectively.
          </p>
        </div>

        {/* Main Content */}
        <div className="space-y-12">
          {/* Section 1: Choose the Right Seat */}
          <section>
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              1. Choose the Right Raised Toilet Seat
            </h2>
            <p className="text-lg text-gray-600 mb-6">
              Before installation, it's essential to ensure the seat is compatible with your toilet and meets the user's needs.
            </p>
            <div className="bg-blue-50 rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Types of Raised Toilet Seats:</h3>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-start">
                  <CheckSquare className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <span><strong>Clamp-On Models:</strong> Attach to the toilet rim with adjustable clamps for stability.</span>
                </li>
                <li className="flex items-start">
                  <CheckSquare className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <span><strong>Bolt-On Models:</strong> Securely fasten using the existing toilet seat bolts for extra durability.</span>
                </li>
                <li className="flex items-start">
                  <CheckSquare className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <span><strong>Freestanding Frames:</strong> Include legs for additional support and do not require attachment to the toilet bowl.</span>
                </li>
              </ul>
            </div>
          </section>

          {/* Section 2: Tools and Materials */}
          <section>
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              2. Gather Necessary Tools and Materials
            </h2>
            <div className="bg-gray-50 rounded-lg p-6">
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-start">
                  <Wrench className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <span>A screwdriver (for bolt-on models)</span>
                </li>
                <li className="flex items-start">
                  <Wrench className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <span>Adjustable wrench (if bolts are tightened with nuts)</span>
                </li>
                <li className="flex items-start">
                  <Wrench className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <span>Cleaning supplies (to sanitize the toilet before installation)</span>
                </li>
                <li className="flex items-start">
                  <Info className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                  <span>Manufacturer's instruction manual</span>
                </li>
              </ul>
            </div>
          </section>

          {/* Installation Steps */}
          <section>
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              3. Installation Steps
            </h2>
            <div className="space-y-6">
              {/* Prepare the Toilet */}
              <div className="bg-white shadow rounded-lg p-6 border border-gray-200">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">Prepare the Toilet</h3>
                <ul className="space-y-4 text-gray-600">
                  <li className="flex items-start">
                    <CheckSquare className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                    <div>
                      <strong>Clean the Surface:</strong>
                      <ul className="ml-9 mt-2 list-disc">
                        <li>Thoroughly clean the toilet bowl rim and surrounding area with a disinfectant</li>
                        <li>Allow the surface to dry completely before proceeding</li>
                      </ul>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <CheckSquare className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                    <div>
                      <strong>Remove the Existing Seat (if needed):</strong>
                      <ul className="ml-9 mt-2 list-disc">
                        <li>For bolt-on models, unscrew the current toilet seat and lid</li>
                        <li>Set aside the seat and bolts in case you need them in the future</li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              {/* Installation by Type */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* Clamp-On Models */}
                <div className="bg-white shadow rounded-lg p-6 border border-gray-200">
                  <h4 className="text-lg font-semibold text-gray-900 mb-4">Clamp-On Models</h4>
                  <ul className="space-y-3 text-gray-600">
                    <li>1. Position the seat over the rim</li>
                    <li>2. Tighten clamps evenly</li>
                    <li>3. Test stability with gentle pressure</li>
                  </ul>
                </div>

                {/* Bolt-On Models */}
                <div className="bg-white shadow rounded-lg p-6 border border-gray-200">
                  <h4 className="text-lg font-semibold text-gray-900 mb-4">Bolt-On Models</h4>
                  <ul className="space-y-3 text-gray-600">
                    <li>1. Align with bolt holes</li>
                    <li>2. Insert and tighten bolts</li>
                    <li>3. Reattach lid if applicable</li>
                    <li>4. Check for movement</li>
                  </ul>
                </div>

                {/* Freestanding Models */}
                <div className="bg-white shadow rounded-lg p-6 border border-gray-200">
                  <h4 className="text-lg font-semibold text-gray-900 mb-4">Freestanding Models</h4>
                  <ul className="space-y-3 text-gray-600">
                    <li>1. Assemble the frame</li>
                    <li>2. Adjust to desired height</li>
                    <li>3. Center around toilet bowl</li>
                    <li>4. Verify leg stability</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* Safety Precautions */}
          <section>
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              4. Safety Precautions
            </h2>
            <div className="bg-yellow-50 rounded-lg p-6">
              <ul className="space-y-4">
                {['Follow manufacturer instructions',
                  'Inspect regularly for wear and loose components',
                  'Respect weight limits',
                  'Consider additional safety accessories'].map((item, index) => (
                  <li key={index} className="flex items-start">
                    <AlertTriangle className="h-6 w-6 text-yellow-600 mt-1 mr-3" />
                    <span className="text-gray-800">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          {/* Common Mistakes */}
          <section>
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              5. Common Installation Mistakes to Avoid
            </h2>
            <div className="bg-red-50 rounded-lg p-6">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <AlertTriangle className="h-6 w-6 text-red-600 mt-1 mr-3" />
                  <span className="text-gray-800"><strong>Skipping the Cleaning Step:</strong> Installing on a dirty surface can lead to poor adhesion and hygiene issues.</span>
                </li>
                <li className="flex items-start">
                  <AlertTriangle className="h-6 w-6 text-red-600 mt-1 mr-3" />
                  <span className="text-gray-800"><strong>Overtightening Clamps or Bolts:</strong> This can damage the toilet or the seat itself.</span>
                </li>
                <li className="flex items-start">
                  <AlertTriangle className="h-6 w-6 text-red-600 mt-1 mr-3" />
                  <span className="text-gray-800"><strong>Ignoring Instructions:</strong> Not following the manufacturer's guidance may void warranties.</span>
                </li>
              </ul>
            </div>
          </section>

          {/* Conclusion */}
          <section className="bg-blue-50 rounded-lg p-6">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Conclusion</h2>
            <p className="text-lg text-gray-600 mb-6">
              Installing a raised toilet seat doesn't have to be complicated, but careful attention to detail is essential to ensure safety and functionality. By following these tips and always referring to the manufacturer's instructions, you can install the seat securely and confidently.
            </p>
            <div className="flex items-start">
              <Info className="h-6 w-6 text-blue-600 mt-1 mr-3 flex-shrink-0" />
              <p className="text-gray-600">
                <strong>💡 Remember:</strong> Regular maintenance and inspections are just as important as the installation process to ensure the seat remains stable and reliable over time.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
